body.single,
body.blog,
body.search,
body.error404 {
/* body.blog targets not the post, but the archive page of Kegiatan */
	.page-header {
		height: 225px;
		height: 40vh;
	}
}

body.single {
	.entry-title {
		color: $body-color;
	}
	.entry-subtitle {
		color: $gray-light;
		margin-left: 1.75rem;
		
		&:before {
			content:"// ";
			font-size: 1.5rem;
			margin-left: -1.75rem;
		}
	}
	.entry-thumbnail {
	    position: relative;
	}
	
	/* Special for single post of blog (Kegiatan) */
	&.single-post {
		.entry-thumbnail {
			top: 0!important;
			margin-top: -2em;
			padding: 0;
			
			img {
				max-width: 1400px;
			}
		}
	}
}




/* sm */
@media (min-width: 544px) {
	body.single,
	body.blog,
	body.search,
	body.error404 {
		.page-header {
			height: 350px;
			height: 55vh;
		}
	}
}

/* md */
@media (min-width: 768px) {
	body.single,
	body.blog,
	body.search,
	body.error404 {
		.page-header {
			height: 440px;
			height: 75vh;
			
			.header-title {		/* previously .h1, changed to be more specific */
				top: -70px;
			}
		}
	}
	body.single {
		.container {	// tar diganti sama yang lebih representatif selector-nya
			&>.h2:first-child {
				margin-top: -$font-size-h1;
			}
		}
		.entry-thumbnail {
			top: 0;
		}
		.entry-content {
			font-size: initial;
		}
		
		/* Special for blog post (Kegiatan) */
		&.single-post {
			.entry-thumbnail {
				margin-top: -10em;
				padding: initial;
			}
		}
	}
}

/* lg */
@media (min-width: 992px) {
	body.single,
	body.blog,
	body.search,
	body.error404 {
		.page-header {
			height: 500px;
			
			.header-title {		/* previously .h1, changed to be more specific */
				top: -95px;
			}
		}
	}
	body.single {
		.entry-thumbnail {
			top: -10em;
		}
		.entry-content {
			font-size: $font-size-lg;
		}
		
		.container {	// tar diganti sama yang lebih representatif selector-nya
			&>.h2.title-long:first-child {
				width: 315px;
				margin-top: calc(-35px - 2.5rem);
				margin-left: auto;
				margin-right: auto;
			}
		}
	}
}

/* xl */
@media (min-width: 1200px) {
	body.single { /* if thumbnail on the left, that is */
		.entry-thumbnail {
			top: -12em;
		}
		
		.container {	// tar diganti sama yang lebih representatif selector-nya
			&>.h2.title-long:first-child {
				width: 375px;
			}
		}
	}
}