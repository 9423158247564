.content-info {
	background: $black;
	color: $white;
	
	.post-edit-link {
		display: none;
	}
	.wpcw-widget-social li:last-child {
		margin-right: 0;
	}
	.wpcw-widget-contact  {
		li {
			padding: 0;
			margin-bottom: .5rem;
		}
		a:link, a:active {
			color: $white;
			padding-bottom: 2px;
			border-bottom: 2px solid $brand-primary;
		}
		a:hover {
			border-color: $link-hover-color;
			text-decoration: none;
		}
	}
}


/* sm */
@media (min-width: 768px) {
	.content-info {
		.col-md-6:nth-child(2) {
			text-align:right!important;
		}
	}
}