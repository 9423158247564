// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.search-form .search-field {
	width: 100%;
}


.form-control {
	font-size: $font-size-sm;
	color: $gray-light;
	border: 2px solid $gray-lighter;
	padding: .75rem;
	
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: $font-size-xs;
		color: $gray-light;
	}
	&::-moz-placeholder { /* Firefox 19+ */
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: $font-size-xs;
		color: $gray-light;
	}
	&:-ms-input-placeholder { /* IE 10+ */
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: $font-size-xs;
		color: $gray-light;
	}
	&:-moz-placeholder { /* Firefox 18- */
		text-transform: uppercase;
		letter-spacing: 2px;
		font-size: $font-size-xs;
		color: $gray-light;
	}
}

