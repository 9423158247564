.btn {
	text-transform: uppercase;
	letter-spacing: 4px;
	cursor: pointer;
}
.btn-primary, button.close {
	box-shadow: inset 0 0 0 2px $white;
	overflow:hidden;
	position: relative;
	
	&:after {
		background: $white;
		opacity: .25;
		content: "";
		height: 155px;
		left: -75px;
		position: absolute;
		top: -50px;
		transform: rotate(35deg);
		transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
		width: 50px;
		z-index: 5;
	}
	
	/* TEXT CHANGE EFFECT */
	&:before, &>span {
		-webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
		transition: transform 0.3s, opacity 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}
	&:before {
		content: attr(data-text);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 25%;
		left: 0;
		opacity: 0;
		-webkit-transform: translate3d(-15%, 0, 0);
		transform: translate3d(-15%, 0, 0);
	}
	/* TEXT CHANGE EFFECT */
	
	&:hover {
	background: lighten($brand-primary, 15%);
  
		&:after {
			left: 120%;
			transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
		}
		
		/* TEXT CHANGE EFFECT */
		&:before {
			opacity: 1;
			-webkit-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
		
		&>span {
			opacity: 0;
			-webkit-transform: translate3d(15%, 0, 0);
			transform: translate3d(15%, 0, 0);
		}
		/* TEXT CHANGE EFFECT */
		
	}
	&:active,
	&:focus {
		color: $white;
	}
}
.btn-outline-secondary {
    position: relative;
    overflow: hidden;
	
	/* TEXT CHANGE EFFECT */
	&:before, &>span {
		-webkit-transition: opacity 0.3s, clip-path 0.3s;
		transition: opacity 0.3s, clip-path 0.3s;
		-webkit-transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
		transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
	}
	&:before {
		content: attr(data-text);
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		opacity: 0;
		-webkit-clip-path: inset(0px 50% 0px 50%);
		clip-path: inset(0px 50% 0px 50%);
	}
	/* TEXT CHANGE EFFECT */
	
	&:hover, 
	&:active {
		color: $brand-primary;
	}
	&:hover {
		background: none;
		
		/* TEXT CHANGE EFFECT */
		&:before {
			opacity: 1;
			-webkit-clip-path: inset(0px 0px 0px 0px);
			clip-path: inset(0px 0px 0px 0px);
		}
		
		&>span {
			opacity: 0;
			-webkit-clip-path: inset(0px 50% 0px 50%);
			clip-path: inset(0px 50% 0px 50%);
		}
		/* TEXT CHANGE EFFECT */
	}
	&:active,
	&:focus {
		background: $white;
	}
	
	&:after {
		content: "";
		position: absolute;
		z-index: -1;
		-webkit-transition: all 0.3s;
		-moz-transition: all 0.3s;
		transition: all 0.3s;
		
		width: 100%;
		height: 0;
		top: 50%;
		left: 50%;
		background: $white;
		opacity: 0;
		-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
		-moz-transform: translateX(-50%) translateY(-50%) rotate(45deg);
		-ms-transform: translateX(-50%) translateY(-50%) rotate(45deg);
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}
	
	&:hover:after,
	&:active:after {
		height: 475%;
		opacity: 1;
	}
}