.home-kegiatan-post {
	position: relative; 
	max-height: 500px;

	.entry-head {
		position: absolute;
		bottom: 0;
		left: .875rem;
		right: .875rem;
		text-align: left;
		color: $white;
	}
	h3 {
		color: $white;
	}
}

.home-publikasi-pub {
	.entry-thumbnail {
		img {
			box-shadow: 0 1px 2px rgba(0,0,0,0.45);
			-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
			transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
		}
	}
	&:hover {
		.entry-thumbnail {
			img {
				box-shadow: 0 5px 15px rgba(0, 0, 0, 0.75);
			}
		}
	}
}


.post-type-archive .home-awak {
	&-foto {
		width: 380px;
		height: 380px;
	}
}

.post-type-archive .cat-title {
	a:hover, a:focus {
		text-decoration: none;
		border-bottom: 2px solid;
		
		span {
			color: $link-hover-color;
		}
	}
}

.post-type-archive-awak {
	.entry-thumbnail {
		height: 340px;
	}
	.entry-head {
		position: absolute;
		left: 0;
		bottom: 3em;
		right: 0;
		
		-webkit-transition: .3s ease-out;
		transition: .3s ease-out;
	}
	.entry-title {
		display: inline-block;
		box-shadow: 0 3px 3px rgba(0,0,0,.06), 0 2px 2px rgba(0,0,0,.12);
		padding: .5rem 1rem;
		background: #fff;
	}
}

body.home .entry-thumbnail.image-red,
body.home .entry-thumbnail.image-none,
body.blog .entry-thumbnail.image-red,
body.blog .entry-thumbnail.image-none,
body.search .entry-thumbnail.image-red,
body.search .entry-thumbnail.image-none {
	min-height: 205px;	/* 320px screen width */
}

/* sm */
@media (min-width: 544px) {
	.post-type-archive .home-awak {
		&-foto {
			height: 270px;
		}
	}
	.post-type-archive-awak {
		.entry-thumbnail {
			width: 85%;
			height: 250px;
		}
		.entry-title {
			font-size:$font-size-h5;
		}
	}
	body.home .entry-thumbnail.image-red,
	body.home .entry-thumbnail.image-none,
	body.blog .entry-thumbnail.image-red,
	body.blog .entry-thumbnail.image-none,
	body.search .entry-thumbnail.image-red,
	body.search .entry-thumbnail.image-none {
		min-height: 373px;
	}
}

/* md */
@media (min-width: 768px) {
	.post-type-archive .home-awak {
		&-foto {
			height: 330px;
		}
	}
	.post-type-archive-awak {
		.entry-thumbnail {
			height: 300px;
		}
		.entry-title {
			font-size:$font-size-h4;
		}
	}
	body.home .entry-thumbnail.image-red,
	body.home .entry-thumbnail.image-none,
	body.blog .entry-thumbnail.image-red,
	body.blog .entry-thumbnail.image-none,
	body.search .entry-thumbnail.image-red,
	body.search .entry-thumbnail.image-none {
		min-height: 248px;
	}
}

/* lg */
@media (min-width: 992px) {
	.post-type-archive .home-awak {
		&-foto {
			height: 290px;
		}
	}
	.post-type-archive-awak {
		.entry-thumbnail {
			height: 250px;
		}
	}
	body.home .entry-thumbnail.image-red,
	body.home .entry-thumbnail.image-none,
	body.blog .entry-thumbnail.image-red,
	body.blog .entry-thumbnail.image-none,
	body.search .entry-thumbnail.image-red,
	body.search .entry-thumbnail.image-none {
		min-height: 338px;
	}
}

/* xl */
@media (min-width: 1200px) {
	.post-type-archive .home-awak {
		&-foto {
			height: 380px;
		}
	}
	.post-type-archive-awak {
		.entry-thumbnail {
			height: 340px;
		}
	}
	body.home .entry-thumbnail.image-red,
	body.home .entry-thumbnail.image-none,
	body.blog .entry-thumbnail.image-red,
	body.blog .entry-thumbnail.image-none,
	body.search .entry-thumbnail.image-red,
	body.search .entry-thumbnail.image-none {
		min-height: 405px;
	}
}