html, body {
	overflow-x: hidden;
}
b, strong {
	font-weight: $font-weight-bold;
}

.page-item .page-link {
	border-radius: 0!important;
	font-weight: $font-weight-bold;
}
.page-item:first-child .page-link {
	color: $body-color;
}
.page-item:first-child .page-link:focus,
.page-item:first-child .page-link:hover {
	color: $body-color;
	background-color: $white;
}

.layer-top {
	position: relative;
	z-index: 11;
}
.layer-top-abs {
	position: absolute;
	z-index: 11;
}
.overlay-color {
	position: relative;

	&:before {
		content: '';
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}
.overlay-black {
	&:before {
		background-color: $black;
	}
}
.overlay-red {
	&:before {
		background-color: $brand-primary;
	}
}
.overlay-quarter {
	&:before {
		opacity: 0.25;
		-webkit-transition: opacity .3s ease-in-out;
		transition: opacity .3s ease-in-out;
	}
}
.overlay-half {
	&:before {
		opacity: 0.8;
		-webkit-transition: opacity .3s ease-in-out;
		transition: opacity .3s ease-in-out;
	}
}
a:hover {
	.overlay-quarter:before {
		opacity: 0.5;
	}
	.overlay-half:before {
		opacity: 0.75;
	}
}
.fit-width {
	width: 100%;
	height: auto;
}
.fit-height {
	width: auto;
	height: 100%;
}
.image-none {
	background: #EFEFEF url(../../dist/images/no-photo.png) no-repeat center;
	background-size: contain;
}
.image-red {
	background: $link-hover-color; /* Old browsers */
	background: -moz-linear-gradient(25deg,#ffc88b,#ffc88b 28%,#ffc388 0,#ffc388 29%,#ffbf85 0,#ffbf85 30%,#ffbb82 0,#ffb880 32%,#ffb47d 0,#ffb17b 33%,#ffad78 0,#f9a36c 34%,#f19666 37%,#ffa170 38%,#f19e6e 39%,#ff9669 41%,#f78158 42%,rgb(249, 114, 73) 44%,#ef4d39 46%,#f5462a 47%,#ef4734 48%,#fa3827 50%,#ef3222 55%,#ce2214 63%,#b52114 73%,#9a1408 89%,#a21f15); /* FF3.6-15 */
	background: -webkit-linear-gradient(25deg,#ffc88b,#ffc88b 28%,#ffc388 0,#ffc388 29%,#ffbf85 0,#ffbf85 30%,#ffbb82 0,#ffb880 32%,#ffb47d 0,#ffb17b 33%,#ffad78 0,#f9a36c 34%,#f19666 37%,#ffa170 38%,#f19e6e 39%,#ff9669 41%,#f78158 42%,rgb(249, 114, 73) 44%,#ef4d39 46%,#f5462a 47%,#ef4734 48%,#fa3827 50%,#ef3222 55%,#ce2214 63%,#b52114 73%,#9a1408 89%,#a21f15); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(25deg,#ffc88b,#ffc88b 28%,#ffc388 0,#ffc388 29%,#ffbf85 0,#ffbf85 30%,#ffbb82 0,#ffb880 32%,#ffb47d 0,#ffb17b 33%,#ffad78 0,#f9a36c 34%,#f19666 37%,#ffa170 38%,#f19e6e 39%,#ff9669 41%,#f78158 42%,rgb(249, 114, 73) 44%,#ef4d39 46%,#f5462a 47%,#ef4734 48%,#fa3827 50%,#ef3222 55%,#ce2214 63%,#b52114 73%,#9a1408 89%,#a21f15); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	background-position: center center;
    background-size: cover;
}
.frame-hide {
	overflow: hidden;
}
.container-break-full {
	width: 100vw;
	position: relative;
	left: 50%;
	right: 50%;
	margin-left: -50vw;
	margin-right: -50vw;
}
.container-break-half {
    position: relative;
}

@keyframes slideFromBottom {
  0% {
    transform: translateY(300%) scale(0,0);
	opacity: .5;
  }
  100% {
    transform: translateY(0) scale(1,1);
	opacity: 1;
  }
}
@keyframes fadeIn {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}
@keyframes fadeInScale {
    from {
        opacity:0;
		transform:scale(0,0);
    }
    to {
        opacity:1;
		transform:scale(1,1);
    }
}
 
 


/* Carousel base class */
.carousel {
  &:after {
    content: '';
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    min-width: 1400px;
    height: 483px;
    background: url(../../dist/images/pattern-triangle.png) 50% 50% no-repeat;
  }
  
  &-inner {
	background-color: $gray;
  }
}
/* Since positioning the image, we need to help out the caption */
.carousel-caption {
  z-index: 13;
  top: 10%;
}
.carousel-title {
	animation: 1s ease-in-out 0s 1 slideFromBottom;

	font-weight: $font-weight-normal;
	color: $white;
	
	&.h1 {
		font-size: $font-size-h2;
	}
}
.carousel-content {
	animation: .75s ease-out 0s 1 fadeInScale;
}
.carousel-desc {
	position: absolute;
    bottom: 35%;
    left: 0;
    right: 0;
}

/* Declare heights because of positioning of img element */
.carousel-item {
  height: 840px;
  
  background-position: 50% 0%;
  background-attachment: fixed;
  animation: 1s ease-in-out 0s 1 fadeIn;
}
.carousel-item > img {
  position: absolute;
  top: 0;
  left: 0;
  min-width: 100%;
}


@media (min-width: 40em) {
  /* Bump up size of carousel content */
  .carousel-caption p {
    margin-bottom: 1.25rem;
    font-size: 1.25rem;
    line-height: 1.4;
  }
}
@media screen and (max-width: 1400px) {
	.carousel:after {
		min-width: inherit;
	}
}

 
 
 

.home-tentang {
	position: relative;
	height: 625px;
	
	&:before {
		content: '';
		position: absolute;
		z-index: 9;
		top:0;
		left:0;
		right:0;
		width: 100%;
		height: 300px;
		background-color: $brand-primary;
	}
	&:after {
		content: '';
		position: absolute;
		z-index: 9;
		bottom: -290px;
		left: 0;
		width: 100%;
		min-width: 1400px;
		height: 100%;
		background: url(../../dist/images/pattern-diagonal.png) 50% 100% no-repeat;
		background-size: cover;
	}
	
 }



.home-awak {
	h2 {
		position: relative;
		top:-1.75rem;
	}
	.row {
		position: relative;
	}
	&-excerpt {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	&-foto {
		img {
			position: relative;
			top: -25px;
			// left: -25%
		}
		img[src*="anna"],
		img[src*="heru"],
		img[src*="dirga"]
		/* defying fit width */ {
			width: 125%;
			left: -25%;
		}
		a img {
			-webkit-transform: scale(1);
			transform: scale(1);
			-webkit-transition: .3s ease-in-out;
			transition: .3s ease-in-out;
		}
		&:hover {
			.entry-head {
				bottom: 2em;
			}
			a img {
				-webkit-transform: scale(1.25);
				transform: scale(1.25);
			}
		}
	}
	img {
		-webkit-filter: grayscale(1);
		filter: grayscale(1);
	}
	.btn {
		margin: 0 auto;
		left: 0;
		right: 0;
		
		margin-top: 1.5em;
	}
}

.home-repeatable {
}

.home-kegiatan {
	margin-top: 125px;
	margin-bottom: 125px;
	position: relative;
	background: $gray-lightest;	/* nanti urusan margin dan BG ini digeser ke class "gray" */

	&:before {
		content: "";
		position: absolute;
		z-index: 9;
		top: -492px;
		left: 0;
		width: 100%;
		min-width: 1400px;
		height: 492px;
		background: url(../../dist/images/pattern-diagonal-gray-top.png) 50% 100% no-repeat;
		background-size: cover;
	}
	&:after {
		content: "";
		position: absolute;
		z-index: 9;
		bottom: -492px;
		left: 0;
		width: 100%;
		min-width: 1400px;
		height: 492px;
		background: url(../../dist/images/pattern-diagonal-gray-bottom.png) 50% 100% no-repeat;
		background-size: cover;
	}
	
	h2 {
		position: relative;
	}
}

.home-pusatdata {
	background-color: $brand-primary;
	background: $brand-primary url(../../dist/images/peta-indonesia.jpg) center center repeat;
    background-size: cover;
	background-attachment: fixed;
}
.home-pusatdata-links .col img {
	-webkit-transition: opacity 0.3s, -webkit-transform 0.3s;
    transition: opacity 0.3s, transform 0.3s;
}
.home-pusatdata-links .col:hover img {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
	opacity: 0.65;
}



/* sm */
@media (min-width: 544px) {
	.carousel-item {
		height: 840px;
	}
	.carousel-caption {
		top: 15%;
	}
	.carousel-title {
		&.h1 {
			font-size: $font-size-h1;
		}
	}
	.carousel-desc {
		width: 50%;
	}
	.home-tentang {
		height: 625px;
		
		&:before {
			height: 225px;
		}
		&:after {
			bottom: -200px;
		
		}
	}
}


/* md */
@media (min-width: 768px) {
	.carousel-item {
		height: 960px;
		background-position: 50% 25%;
		background-size: cover;
	}
	.carousel-caption {
		top: 20%;
	}
	.home-tentang {
		height: 625px;
		
		&:before {
			height: 100px;
		}
		&:after {
			bottom: -90px;
		
		}
	}
	.home-awak {
		&-foto {
			height: 160px;
		}
		.btn {
			width: 50%;
			bottom: 8%;	/* aslinya top: 50%; */
			
			&.home-awak-overlay {	/* dibikin conditional */
				position: absolute;
				margin-top: 0;
			}
		}
	}
	
	.home-pusatdata-links .col .row:before {
		content: "";
		display:block;
		z-index: 5;
		background: rgba(0,0,0,0.65);
		position: absolute;
		opacity: 0;
		-webkit-transition: all 0.3s ease-in-out 0s;
		-moz-transition: all 0.3s ease-in-out 0s;
		transition: all 0.3s ease-in-out 0s;
	
		/* Transformer rotation */
		height: 0;
		width: 0;
		top: 50%;
		left: 50%;
		bottom: 0;
		right: 0;
		-webkit-transform: translateX(-50%) translateY(-50%) rotate(45deg);
		transform: translateX(-50%) translateY(-50%) rotate(45deg);
	}
	.home-pusatdata-links .col:hover .row:before {
		opacity: 1;
		height: 475%;
		width: 85%;
	}

	.home-pusatdata-links .col .entry-head {
		position: absolute;
		z-index: 6;
		text-align: center;
		padding-left: 1em;
		padding-right: 1em;
		width: 100%;
		top: 80%;
		left: 50%;
		opacity: 0;
		-webkit-transform: translate(-50%, -50%);
		-moz-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		-webkit-transition: all 0.25s ease-in-out 0s;
		-moz-transition: all 0.25s ease-in-out 0s;
		transition: all 0.25s ease-in-out 0s;
	}
	.home-pusatdata-links .col:hover .entry-head {
		top: 50%;
		left: 50%;
		opacity: 1;
	}

	.home-pusatdata-links .col:hover img {
		-webkit-transform: scale(0.65);
		transform: scale(0.65);
	}

}

/* lg */
@media (min-width: 992px) {
	.container-break-half {
		width: 75vw;
		left: 26%;
		right: 26%;
		margin-left: -25vw;
		margin-right: -25vw;
	}

	.home-awak {
		&-foto {
			height: 135px;
		}
		/* no need to, udah pake flexbox biar vertically center
		&-excerpt {
			padding-top: .875rem;
		}
		*/
		.btn {
			bottom: 11%;	/* aslinya top: 70%; */
			width: 25%;
		}
	}

}

/* xl */
@media (min-width: 1200px) {
	.carousel-item {
		height: 1028px;
	}
	.carousel-caption {
		right: 20%;
		left: 20%;
	}
	.home-awak {
		&-foto {
			height: 175px;
		}
		/* no need to, udah pake flexbox biar vertically center
		&-excerpt {
			padding-top: 2.625rem;
		}
		*/
	}
}



/*
.home-tentang {
    background: rgba(0, 0, 0, 0) url(../../assets/images/pattern-diagonal.png) no-repeat scroll center top;
    height: 1681px;
    position: absolute;
    right: 0;
    top: -720px;
    width: 100%;
    z-index: 3;
}
*/