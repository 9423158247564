.page-header {
    height: 450px;
	height: 80vh;
    position: relative;

	background-attachment: fixed;
	
    display: flex;
    flex-direction: column;
    justify-content: center;
	
	.header-title {		/* previously .h1 and h1, changed to be more specific */
	    position: relative;
		top: -30px;
		top: -5vh;
		text-align: center;
	}
	
	.header-title a:link,
	.header-title a:active {
		box-shadow: 0px 4px 0px 0px $gray-lighter;
	}
	.header-title a:hover,
	.header-title a:focus {
		box-shadow: 0px 4px 0px 0px $white;
		text-decoration: none;
	}
}
.page-header:after {
    content: "";
    position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    min-width: 1400px;
    height: 100%;
    background: url(../../dist/images/pattern-triangle-white.png) 50% 100% no-repeat;
    background-size: contain;
}
@media screen and (max-width: 1400px) {
	.page-header:after {
		min-width: inherit;
	}
}

.entry-content {
	font-size: $font-size-lg;
	margin-bottom: ($spacer * 1.5);
	
	h1, h2, h3, h4, h5, h6 {
		margin-bottom: ($spacer * 1.5);
	}
	p, blockquote, ul, ol {
		margin-bottom: ($spacer * 1.125);
	}
	
	ul, ol {
		margin-left: 0;
		padding-left: 0;
		
		& > li {
			display: block;
			position: relative; 
			margin:0 0 6px 2rem;
			list-style:none;
		}
		
		& li:before {
			color: $brand-primary;
			font-weight: $font-weight-bold;
			
			position:absolute;
			left: -2rem;
			box-sizing:border-box;
			width:2em;
		}
	}
	ol {
		counter-reset: item;
	}
    ol li:before {
        content: counter(item) ". ";
        counter-increment: item;
    }
	ul li:before {
		content: "\25B6 ";
	}
	
	& > div[style*="width:100%"] {		// widget Wordpress Downloads, damn why don't you make proper class
		h3 {
			color: $black;
			font-size: $font-size-lg;
			margin-bottom: ($spacer * 1.125);
		}
	}
	.post-attachment {
		margin-left: 0;
	    background-position: 0px 8px;
		
		&:before {
			display: none;
		}
	}
	.wpcw-widget-contact {
		font-size: initial;
		
		li {
			&:before {
				color: $brand-primary;
				font: normal normal normal 14px/1 FontAwesome;
				display: inline-block;
				top: 12px;
				font-size: 1.25em;
				content: "";
			}
			& > strong {
				font-weight: $font-weight-normal;
				color: $gray-light;
				
				text-transform: uppercase;
				letter-spacing: 4px;
				font-size: $font-size-sm;
			}
			& > div {
				font-weight: $font-weight-bold;
			}
		}
		li:first-child:before {
			content: "\f232";
		}
		li:nth-child(2):before {
			content: "\f0e0";
		}
		li:last-child:before {
			content: "\f041";
		}
		.post-edit-link {
			display: none;
		}
	}
	div.wpcf7 .ajax-loader {
		margin: 0 auto;
		display: block;
		text-align: center;
	}
}



/* sm */
@media (min-width: 544px) {

}

/* md */
@media (min-width: 768px) {
	.page-header {
		height: 560px;
		height: 100vh;
	}
	.entry-content {	// tar diganti sama yang lebih representatif selector-nya
		&>h2:first-child {
			margin-top: -$font-size-h1;
		}
	}
	.search-title {
		margin-top: -$font-size-h1;
	}
}

/* lg */
@media (min-width: 992px) {
	.page-header {
		height: 750px;
		
		.header-title {		/* previously .h1, changed to be more specific */
			top: -95px;
		}
	}

}

/* xl */
@media (min-width: 1200px) {

}
