.navbar {
	.navbar-brand {
		padding-top:0;
		padding-bottom: 0;
	}
	.brand-image {
		width: auto;
		height: 60px;
	}
	.navbar-collapse {
		width: auto;
		margin-top: .5rem;
	}
	.nav-link {
		font-weight: $font-weight-bold;
	}
	.navbar-toggler-right {
		top: 1rem;
	}
}
.dropdown-menu {
	border: 0;
	border-radius: 0;
	
	padding-left: 1em;
	margin-top: .25rem;
	
	display: block;
	opacity: 0;
	
	-webkit-transition: all 0.15s ease;
	-moz-transition: all 0.15s ease;
	-o-transition: all 0.15s ease;
	transition: all 0.15s ease;
}
.dropdown-menu a {
    height: 1px;
    font-size: 1px;
	
	-webkit-transition: all 0.15s ease;
	-moz-transition: all 0.15s ease;
	-o-transition: all 0.15s ease;
	transition: all 0.15s ease;
}
.show>.dropdown-menu {
	margin-top: -.25rem;
	opacity: 1;
}
.show>.dropdown-menu a {
    height: initial;
    font-size: initial;
}
@media (max-width: 991px) {
	.navbar-toggleable-md .navbar-nav .dropdown-menu {
		position: absolute;
	}
}
@media (max-width: 991px) {
	.navbar-toggleable-md .navbar-nav .show>.dropdown-menu {
		position: static;
	}
}

.nav-search li {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
#search-button {
	font-weight: $font-weight-bold;
	display: block;
	padding: .5rem 0;
	
	&:hover,
	&:active,
	&:focus {
		text-decoration: none;
	}
}
#search {
    position: fixed;
	z-index: 1000;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    
    -webkit-transition: all 0.5s ease-in-out;
	-moz-transition: all 0.5s ease-in-out;
	-o-transition: all 0.5s ease-in-out;
	-ms-transition: all 0.5s ease-in-out;
	transition: all 0.5s ease-in-out;

    -webkit-transform: translate(0px, -100%) scale(0, 0);
	-moz-transform: translate(0px, -100%) scale(0, 0);
	-o-transform: translate(0px, -100%) scale(0, 0);
	-ms-transform: translate(0px, -100%) scale(0, 0);
	transform: translate(0px, -100%) scale(0, 0);
    
    opacity: 0;
}

#search.open {
    -webkit-transform: translate(0px, 0px) scale(1, 1);
    -moz-transform: translate(0px, 0px) scale(1, 1);
	-o-transform: translate(0px, 0px) scale(1, 1);
	-ms-transform: translate(0px, 0px) scale(1, 1);
	transform: translate(0px, 0px) scale(1, 1); 
    opacity: 1;
}

#search input[type="search"] {
    position: absolute;
    top: 50%;
    width: 100%;
    color: $white;
    background: rgba(0, 0, 0, 0);
    font-size: 60px;
    font-weight: 300;
    text-align: center;
    border: 0px;
    margin: 0px auto;
    margin-top: -51px;
    padding-left: 30px;
    padding-right: 30px;
    outline: none;
}
#search .submit {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 61px;
    margin-left: -45px;
}
#search .close {
    position: fixed;
    top: 15px;
    right: 15px;
    color: $white;
	background-color: $brand-primary;
	opacity: 1;
	padding: 10px 17px;
	font-size: 27px;
}

/* sm */
@media (min-width: 768px) {
}

/* md */
@media (min-width: 992px) {
	.navbar {
		.navbar-nav {
			margin-top: 0;
		}
		.nav-link {
			padding-left: 1em;
		}
	}
	.dropdown-menu {
		padding: 0;
		box-shadow: 0 3px 3px rgba(0,0,0,0.06), 0 2px 2px rgba(0,0,0,0.12);
		top: 110%;
	}
	#search-button {
		padding: 0 0 0 1rem;
	}
}

/* lg */
@media (min-width: 1200px) {
}



