// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;


// Colors
$brand-primary:         #b62619;

$white:  #fff;
$black:  #0e0c0c;

$gray-dark:   	#2D2D2D; //383838 tadinya
$gray:        	#585858;
$gray-light:  	#8a8a8a;
$gray-lighter:	#CECECE;
$gray-lightest: #EFEFEF;


// Globals
$body-bg:       $white;
$body-color:    $gray-dark;

$link-color:            $brand-primary;
$link-decoration:       none;
$link-hover-color:      lighten($link-color, 15%);
$link-hover-decoration: underline;

$spacer: 1.75rem;

$btn-secondary-color:            $white;
$btn-secondary-bg:               $gray-lighter;
$btn-secondary-border:           $white;

$btn-border-radius:		0;
$btn-border-radius-lg:	0;
$btn-border-radius-sm:	0;

$input-border-radius:            0;
$input-border-radius-lg:         0;
$input-border-radius-sm:         0;

// Fonts
$font-family-sans-serif: 'Fira Sans', sans-serif;

$font-weight-normal: 	300;
$font-weight-bold: 		700;
$headings-font-weight: 	700;
$lead-font-weight: 		300;

$headings-color:	$brand-primary;

$text-muted: 		$gray-lighter;

// Pagination
$pagination-border-color:              $gray-lighter;

$pagination-hover-bg:                  $gray-lightest;
$pagination-hover-border:              $gray-lighter;

$pagination-disabled-color:            $gray-lighter;
$pagination-disabled-border:           $gray-lighter;